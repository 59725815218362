<template>
  <card>
    <template slot="header">
      <p class="card-header-title">
        <strong>Reports</strong>
      </p>
    </template>
    <template slot="body">
      <div class="is-flex has-items-aligned-start">
        <p>
          Export a summary (CSV) of all tasks created over the last 30 days,
          including ratings, feedback and time to first reply.
        </p>
        <button
          class="button has-margin-left-75 has-margin-top-25"
          :class="{ 'is-loading': processing }"
          @click="exportCSV"
        >
          <b-icon icon="download" size="is-small" />
        </button>
      </div>
    </template>
  </card>
</template>

<script>
import { downloadBlob } from "@src/services/fileutil";
export default {
  name: "ResellerReports",
  props: {
    resellerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      processing: false
    };
  },
  methods: {
    exportCSV() {
      this.processing = true;
      this.$store
        .dispatch("reseller/getTasksReport", { resellerId: this.resellerId })
        .then(result => {
          var blob = new Blob([result.data], {
            type: "text/csv;charset=utf-8;"
          });
          const filename = `${this.$moment().format("YYYY-MM-DD")}-tasks.csv`;
          downloadBlob(blob, filename);
        })
        .catch(() => {
          this.$toast.open({
            message: "Error generating csv file",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
